































import { debounceProcess } from "@/helpers/debounce";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import Vue from "vue";
import { IOption } from "@/models/interface-v2/common.interface";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { jobCostingService } from "@/services-v2/job-costing.service";
import { ResponseJobCosting, ResponseListJobCosting } from "@/models/interface-v2/job-costing.interface";

export default Vue.extend({
  name: "CSelectJobCostingNumber",
  props: {
    value: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    forList: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    this.searchDoc = debounceProcess(this.searchDoc, 300);
    return {
      loading: false,
      dtList: {} as ResponseListJobCosting,
      dtOpt: [] as IOption<ResponseJobCosting>[],
      queryParams: {
        page: 0,
        limit: DEFAULT_PAGE_SIZE,
        search: ""
      }
    };
  },
  watch: {
    value(newValue: string) {
      if (newValue) {
        this.handleMissingOpt();
      }
    },
  },
  created() {
    this.getList(this.queryParams);
    this.handleMissingOpt();
  },
  methods: {
    getListDoc(params: RequestQueryParamsModel): Promise<ResponseListJobCosting> {
      return jobCostingService.getListJobCosting(params);
    },
    onSelect(val: string, meta: ResponseJobCosting): void {
      this.$emit("input", val);
      this.$emit("on-select", { value: val, meta });
    },
    searchDoc(search = ""): void {
      if (search) {
        this.queryParams.search = `jobCostingNumber~*${search}*`;
      }
      this.queryParams.page = 0;
      this.dtOpt = [];
      this.getList(this.queryParams);
    },
    popupScroll(e): void {
      if ((this.dtList.totalPages - 1) === this.dtList.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getList(this.queryParams);
      }
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListDoc(params);
        const { dtOpt } = this;
        const opts = res.data.map(x => (
          {
            key: x.jobCostingNumber,
            value: x.id,
            meta: x
          }
        ));
        this.dtOpt = [...dtOpt, ...opts];
        this.dtList = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    async handleMissingOpt(): Promise<void> {
      try {
        if (this.forList || !this.value) return;
        const opt = this.dtOpt.find(x => x.value === this.value);
        if (!opt) {
          const { data } = await this.getListDoc({ search: `secureId~${this.value}` });
          const newOpt = { key: data[0].jobCostingNumber, value: data[0].id, meta: data[0] };
          const { dtOpt } = this;
          this.dtOpt = [...dtOpt, newOpt];
        }
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
  }
});

