var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        placeholder: _vm.$t("lbl_choose"),
        "show-search": true,
        "filter-option": false,
        loading: _vm.loading,
        "allow-clear": true,
        disabled: _vm.disabled,
        value: _vm.value,
        "dropdown-match-select-width": false
      },
      on: {
        search: function(value) {
          return _vm.searchDoc(value)
        },
        popupScroll: _vm.popupScroll
      }
    },
    _vm._l(_vm.dtOpt, function(data) {
      return _c(
        "a-select-option",
        {
          key: data.key,
          attrs: { value: data.value },
          on: {
            click: function() {
              return _vm.onSelect(data.value, data.meta)
            }
          }
        },
        [
          _c(
            "a-tooltip",
            [
              _c("template", { slot: "title" }, [
                _vm._v(" " + _vm._s(data.key || "-") + " ")
              ]),
              _vm._v(" " + _vm._s(data.key || "-") + " ")
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }